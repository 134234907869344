import { render, staticRenderFns } from "./header.vue?vue&type=template&id=0e7c482c&scoped=true&"
import script from "@/assets/js/components/header.js?vue&type=script&lang=js&"
export * from "@/assets/js/components/header.js?vue&type=script&lang=js&"
import style0 from "@/assets/scss/components/header.scss?vue&type=style&index=0&id=0e7c482c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e7c482c",
  null
  
)

export default component.exports